import { useInfiniteQuery } from '@tanstack/react-query';

import { queryKeys, constants } from 'common';
import { IPaginator } from 'common/types';
import { productAPI } from 'api';
import { IFilterData } from 'api/product/types';

const { searchProductAPI } = productAPI;

export const useHomeProducts = (filterData?: IFilterData) => {
    const { data, isLoading, refetch, fetchNextPage, hasNextPage, isFetching, isError } = useInfiniteQuery(
        [queryKeys.GET_HOME_PAGE_PRODUCTS],
        async ({ pageParam }) => {
            const paginator: Partial<IPaginator> = {
                page: pageParam,
                perPage: constants.PAGING.productsPerPage,
                orderDir: filterData?.sortByCreatedAt || 'DESC',
                orderBy: 'createdAt',
            };

            if (filterData?.sortByCreatedAt) {
                delete filterData.sortByCreatedAt;
            }

            return await searchProductAPI(paginator, filterData);
        },
        {
            getNextPageParam: (lastPage) => {
                const paginator: IPaginator = lastPage.paginator;
                return paginator.page < paginator.totalPages ? paginator.page + 1 : false;
            },
            staleTime: Infinity,
            cacheTime: Infinity,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            keepPreviousData: true,
        },
    );

    const loadedData = data?.pages?.flatMap((page) => page?.results) ?? [];

    return {
        data: loadedData,
        isLoading,
        refetch,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isError,
    };
};
