'use client';

import { Button } from 'components';
import { useTranslation } from 'i18n/client';
import { redirectTo, routesConstants } from 'common';
import { getSession } from 'core';

interface IProps {
    lang: string;
}

const { prefixes, routes } = routesConstants;

const HomeWelcomeModal = ({ lang }: IProps) => {
    const { t } = useTranslation(lang, 'home');
    const session = getSession();

    const redirectToPath = (path: string) => {
        redirectTo(path);
    };

    return (
        <div>
            <p className="text-[18px] text-black-100 font-medium text-center mb-12">{t('WELCOME_MODAL_TITLE')}</p>
            <p className="text-sm-title text-grey-300">{t('WELCOME_MODAL_MESSAGE')}</p>
            <div className="flex items-center mt-32">
                <Button variant="secondary" className="mr-4 w-1/2" onClick={() => redirectToPath(`/${lang}${routes[prefixes.home].path}`)}>
                    {t('CONTINUE_LATER')}
                </Button>
                <Button className="ml-4 w-1/2" onClick={() => redirectToPath(`/${lang}${routes[prefixes.kyc].path}/${session?.userDetails?.uuid}`)}>
                    {t('GO_TO_KYC')}
                </Button>
            </div>
        </div>
    );
};

export default HomeWelcomeModal;
