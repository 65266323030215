import {
    VEHICLE_SECTIONS,
    REAL_ESTATE_SECTIONS,
    DEVICES_SECTIONS,
    CARS_FIXED_CATEGORY_FILTERS,
    REAL_ESTATE_CATEGORY_FILTERS,
    DEVICES_CATEGORY_FILTERS,
} from 'app/[locale]/home/constants/home-constants';
import { HOME_FILTER_OPTIONS } from 'components/header/constants';

export const getFirstSection = (slug?: string) => {
    switch (slug) {
        case HOME_FILTER_OPTIONS.cars.value:
            return VEHICLE_SECTIONS.automobili.value;
        case HOME_FILTER_OPTIONS.realestates.value:
            return REAL_ESTATE_SECTIONS.stanovi.value;
        case HOME_FILTER_OPTIONS.technology.value:
            return DEVICES_SECTIONS.mobiteli.value;
        default:
            return '';
    }
};

export const getSections = (slug?: string) => {
    switch (slug) {
        case HOME_FILTER_OPTIONS.cars.value:
            return VEHICLE_SECTIONS;
        case HOME_FILTER_OPTIONS.realestates.value:
            return REAL_ESTATE_SECTIONS;
        case HOME_FILTER_OPTIONS.technology.value:
            return DEVICES_SECTIONS;
        default:
            return {};
    }
};

export const getFixedFields = (slug?: string) => {
    switch (slug) {
        case HOME_FILTER_OPTIONS.cars.value:
            return CARS_FIXED_CATEGORY_FILTERS;
        case HOME_FILTER_OPTIONS.realestates.value:
            return REAL_ESTATE_CATEGORY_FILTERS;
        case HOME_FILTER_OPTIONS.technology.value:
            return DEVICES_CATEGORY_FILTERS;
        default:
            return {};
    }
};

export const breakpoints = {
    xxsm: 245,
    xsm: 314,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1076,
    '2xl': 1280,
    '3xl': 1331,
    '4xl': 1536,
    '5xl': 1637,
};
