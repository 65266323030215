'use client';

import { useMemo, useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import { useTranslation } from 'i18n/client';
import { IProductSearch, IProductField } from 'api/product/types';
import { helpers, routesConstants, constants, queryKeys } from 'common';
import { ArticleCard, Button, Loader } from 'components';
import { useHomeProducts } from 'hooks/use-home-products';
import { breakpoints } from '../helpers/home-helpers';
import { productAPI } from 'api';

interface IProps {
    lang: string;
}

const { divideTwoNumbers, cn } = helpers;
const { routes, prefixes } = routesConstants;
const { searchProductAPI } = productAPI;
const PRODUCT_UUID_KEY = 'product-uuid';

const HomeProducts = ({ lang }: IProps) => {
    const { t } = useTranslation(lang, 'home');

    const { data, fetchNextPage, hasNextPage, isFetching } = useHomeProducts({});

    const { data: randomProductsData, isFetching: isRandomProductsFetching } = useQuery({
        queryKey: [queryKeys.GET_RANDOM_PRODUCTS],
        queryFn: () => searchProductAPI({ page: 1, perPage: 8, orderDir: 'DESC', orderBy: 'createdAt' }, { promotedFilter: true }),
        enabled: true,
        retry: false,
        refetchOnWindowFocus: false,
    });

    const [fieldsToShow, setFieldsToShow] = useState(3);

    useEffect(() => {
        const updateFieldsToShow = () => {
            const width = window.innerWidth;

            const isSmallBox = width <= breakpoints.xxsm || (width <= breakpoints.xl && width >= breakpoints.lg);
            const isMediumBox = width <= breakpoints.xsm || (width <= breakpoints['3xl'] && width > breakpoints.lg) || (width < breakpoints['5xl'] && width >= breakpoints['4xl']);

            if (isSmallBox) {
                setFieldsToShow(1);
            } else if (isMediumBox) {
                setFieldsToShow(2);
            } else {
                setFieldsToShow(3);
            }
        };

        updateFieldsToShow();

        window.addEventListener('resize', updateFieldsToShow);

        return () => {
            window.removeEventListener('resize', updateFieldsToShow);
        };
    }, []);

    const randomProducts = useMemo(() => {
        const productsList = randomProductsData && randomProductsData?.results;

        return productsList && productsList?.length > 0 ? productsList : [];
    }, [randomProductsData]);

    const memoizedRandomCards = useMemo(() => {
        const renderProducts = randomProducts && randomProducts?.length > 0;

        const isProductPromoted = (product: IProductSearch) => {
            return product?.promotedUntil ? true : false;
        };

        return renderProducts
            ? randomProducts.map((product: IProductSearch) => {
                  const isPromoted = isProductPromoted(product);
                  const fields = product?.fields && product.fields.length > 0 ? product.fields.filter((item: IProductField) => item?.productCategoryField?.featured).slice(0, fieldsToShow) : [];

                  return (
                      <div key={`promoted-product-${product.uuid}`} className="cursor-pointer">
                          <ArticleCard
                              imageUrl={product.primaryImageURI}
                              title={product.name}
                              price={divideTwoNumbers(product?.price || 0, 100, true)}
                              isNew={product?.new}
                              newLabel={product?.new ? t('NEW') : ''}
                              currency={product.currency}
                              imagesCount={product?.totalImages || 1}
                              cardClassName="w-full h-[250px] rounded-b-[0px] mb-[0px]"
                              bottomPartClassName={cn('px-[8px] py-[12px] rounded-b-[9px] h-[96px] grid grid-rows-3 bg-white-main')}
                              nameClassName="text-[15px] md:text-[16px] font-normal md:font-light h-[40px] mb-8 overflow-hidden whitespace-nowrap text-ellipsis"
                              priceClassName="text-[14px] font-light md:font-[20px] md:font-semibold mb-2 md:mb-[0px]"
                              path={`/${lang}${routes[prefixes.product].path}/${product.uuid}`}
                              isPromoted={isPromoted}
                              fields={fields}
                              id={product?.uuid}
                              hasVideo={product?.video?.youtubeURI && product?.video?.status === constants.PRODUCT_VIDEO_STATUSES.accepted.value ? true : false}
                              isGift={product?.gift || false}
                              giftLabel={t('GIFT')}
                              showArticleShadow
                              withImage
                          />
                      </div>
                  );
              })
            : [];
    }, [t, lang, fieldsToShow, randomProducts]);

    const memoizedCards = useMemo(() => {
        const renderProducts = data && data?.length > 0;

        const isProductPromoted = (product: IProductSearch) => {
            return product?.promotedUntil ? true : false;
        };

        return renderProducts
            ? data.map((product: IProductSearch) => {
                  const isPromoted = isProductPromoted(product);
                  const fields = product?.fields && product.fields.length > 0 ? product.fields.filter((item: IProductField) => item?.productCategoryField?.featured).slice(0, fieldsToShow) : [];

                  return (
                      <div key={`promoted-product-${product.uuid}`} className="cursor-pointer">
                          <ArticleCard
                              imageUrl={product.primaryImageURI}
                              title={product.name}
                              price={divideTwoNumbers(product?.price || 0, 100, true)}
                              isNew={product?.new}
                              newLabel={product?.new ? t('NEW') : ''}
                              currency={product.currency}
                              imagesCount={product?.totalImages || 1}
                              cardClassName="w-full h-[250px] rounded-b-[0px] mb-[0px]"
                              bottomPartClassName={cn('px-[8px] py-[12px] rounded-b-[9px] h-[96px] grid grid-rows-3 bg-white-main')}
                              nameClassName="text-[15px] md:text-[16px] font-normal md:font-light h-[40px] mb-8 overflow-hidden whitespace-nowrap text-ellipsis"
                              priceClassName="text-[14px] font-light md:font-[20px] md:font-semibold mb-2 md:mb-[0px]"
                              path={`/${lang}${routes[prefixes.product].path}/${product.uuid}`}
                              isPromoted={isPromoted}
                              fields={fields}
                              id={product?.uuid}
                              hasVideo={product?.video?.youtubeURI && product?.video?.status === constants.PRODUCT_VIDEO_STATUSES.accepted.value ? true : false}
                              isGift={product?.gift || false}
                              giftLabel={t('GIFT')}
                              showArticleShadow
                              withImage
                          />
                      </div>
                  );
              })
            : [];
    }, [data, t, lang, fieldsToShow]);

    useEffect(() => {
        let interval: any;

        const scrollToProduct = () => {
            const productUUID = localStorage.getItem(PRODUCT_UUID_KEY);

            if (productUUID) {
                const elememt = document.getElementById(productUUID);

                if (elememt) {
                    localStorage.removeItem(PRODUCT_UUID_KEY);
                    elememt.scrollIntoView({ block: 'center' });

                    if (interval) {
                        clearInterval(interval);
                    }
                }
            } else {
                if (interval) {
                    clearInterval(interval);
                }
            }
        };

        if (data?.length > 0) {
            window.addEventListener('storage', () => {
                interval = setInterval(() => {
                    scrollToProduct();
                }, 300);
            });
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [data]);

    if (isRandomProductsFetching || (isFetching && !data)) {
        return null;
    }

    return (
        <>
            {randomProducts && randomProducts.length > 0 && (
                <div className="bg-yellow-200 px-[16px] md:px-[66px] pb-[66px] pt-[18px] mb-[40px]">
                    <div className="relative pb-[45px] pt-[20px]">
                        <p className="text-xl-title font-semibold z-10 relative">{t('PROMOTED_ARTICLES_TITLE')}</p>
                        <div className="bg-yellow-100 w-[90px] h-[45px] absolute left-[-20px] bottom-[40px] rounded-[25px] z-0" style={{ transform: `rotate(60deg)` }}></div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5 gap-x-24 gap-y-[40px]">{memoizedRandomCards}</div>
                </div>
            )}
            <div className="px-[16px] md:px-[66px] pb-[66px] pt-[18px]">
                <div className="relative pb-[45px] pt-[20px]">
                    <p className="text-xl-title font-semibold z-10 relative">{t('PROMOTED_PRODUCTS')}</p>
                    <div className="bg-yellow-100 w-[90px] h-[45px] absolute left-[-20px] bottom-[40px] rounded-[25px] z-0" style={{ transform: `rotate(60deg)` }}></div>
                </div>
                <div className="w-full">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5 gap-x-24 gap-y-[40px]">{memoizedCards}</div>
                    <div className="w-full flex justify-center items-center mt-[32px]">
                        <Button
                            className={cn(
                                'min-w-[150px] h-[50px] bg-white-main text-[14px] font-semibold text-center rounded-[25px] px-[16px] py-[8px]',
                                isFetching || !hasNextPage ? '!cursor-not-allowed pointer-events-none !bg-grey-200' : '',
                            )}
                            disabled={isFetching || !hasNextPage}
                            onClick={() => {
                                fetchNextPage();
                            }}
                        >
                            {!isFetching && t('LOAD_MORE_BTN')}
                            {isFetching && <Loader className="ml-12" size={12} />}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HomeProducts;
