'use client';

import { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import { useRouter } from 'next/navigation';

import HomeCategories from 'app/[locale]/home/components/home-categories';
import { FILTERS, SUGGEST_TYPES } from 'app/[locale]/home/constants/home-constants';
import { IFilter, ISuggestion, ISuggestOption } from 'app/[locale]/home/types';
import { useTranslation } from 'i18n/client';
import { Button, CountriesSelect, Input } from 'components';
import { helpers, routesConstants, _ } from 'common';
import { sharedAPI, categoryAPI, userAPI } from 'api';

interface IProps {
    lang: string;
}

const { search, location, category } = FILTERS;
const { searchAPI } = sharedAPI;
const { getCategoryBySlugAPI } = categoryAPI;
const { getUserDetailsPublicByUsernameAPI } = userAPI;
const { cn, formQueryParams } = helpers;
const { routes, prefixes } = routesConstants;

const HomeHeader = ({ lang }: IProps) => {
    const { t } = useTranslation(lang, 'home');
    const dropdownRef = useRef<any>();
    const router = useRouter();

    const [filters, setFilters] = useState<IFilter>({ productFilter: '', cityIDFilter: null, categoryIDFilter: null });
    const [suggestions, setSuggestions] = useState<ISuggestion | null>(null);
    const [isSelectedSearch, setIsSelectedSearch] = useState<boolean>(false);

    const getCategory = async (slug: string) => {
        try {
            const category = await getCategoryBySlugAPI(slug);

            return category?.id;
        } catch (error) {
            return null;
        }
    };

    const handleFiltersChange = async (key: string, value?: string | number | null, values?: number[] | string[]) => {
        const filtersCopy: IFilter = Object.assign({}, filters);

        if (key === location.name) {
            const cities = values ? values.join() : '';
            // @ts-ignore
            filtersCopy[key as keyof typeof filtersCopy] = cities;

            setFilters(filtersCopy);

            return;
        }

        // @ts-ignore
        filtersCopy[key as keyof typeof filtersCopy] = value;

        setFilters(filtersCopy);

        if (key === search.name) {
            setIsSelectedSearch(false);
        }
    };

    const debouncedHandleFiltersChange = _.debounce(handleFiltersChange, 300);

    const getDropdownValue = () => {
        const locationValue = filters[location.name as keyof typeof filters];

        if (locationValue) {
            return locationValue.toString().split(',').map(Number);
        } else {
            return [];
        }
    };

    const handleSearch = async () => {
        if (isSelectedSearch) {
            return;
        }

        try {
            let result: ISuggestion | null = null;

            if (filters?.productFilter) {
                result = await searchAPI(filters?.productFilter as string);
            }

            setSuggestions(result);
        } catch (error) {
            setSuggestions(null);
        }
    };

    const memoizedHandleSearch = useCallback(handleSearch, [filters?.productFilter, isSelectedSearch]);

    const handleSelectSearch = async (type: string, value: string) => {
        const filtersCopy = Object.assign({}, filters);
        // @ts-ignore
        filtersCopy[search.name as keyof typeof filtersCopy] = value;

        setIsSelectedSearch(true);

        switch (type) {
            case SUGGEST_TYPES.categories:
                filtersCopy[category.name as keyof typeof filtersCopy] = await getCategory(value);
                delete filtersCopy[search.name as keyof typeof filtersCopy];
                break;
            default:
                break;
        }

        setSuggestions(null);

        if (type !== SUGGEST_TYPES.users) {
            router.push(`/${lang}${routes[prefixes.search].path}${formQueryParams(filtersCopy)}`);
        } else {
            const userDetails = await getUserDetailsPublicByUsernameAPI(value);

            if (userDetails?.uuid) {
                router.push(`/${lang}${routes[prefixes.user].path}/${userDetails?.uuid}`);
            }
        }
    };

    const memoizedHandleSelectSearch = useCallback(handleSelectSearch, [filters, lang, router]);

    const handleCloseDropdown = (e: any) => {
        const clickedInside = dropdownRef && dropdownRef.current && dropdownRef.current.contains(e.target);

        if (clickedInside) {
            return;
        }

        setSuggestions(null);
    };

    const handleRedirectToSearch = (e: any) => {
        e.preventDefault();
        const params = Object.assign({}, filters);

        if (params.categoryIDFilter) {
            params.productFilter = null;
        }

        router.push(`/${lang}${routes[prefixes.search].path}${formQueryParams(params)}`);
    };

    const customDropdown = useMemo(() => {
        const renderOptions = (type: string, options?: ISuggestOption[]) => {
            const shouldRenderOptions = options && options.length > 0;

            return shouldRenderOptions
                ? options.map((item: ISuggestOption, index: number) => (
                      <div
                          key={`suggestion-option-${type}-${index}`}
                          className="py-[18px] border-b border-b-grey-900 text-black-main text-sm-title cursor-pointer"
                          onClick={(e) => {
                              e.stopPropagation();
                              memoizedHandleSelectSearch(type, item.text);
                          }}
                      >
                          {item.text}
                      </div>
                  ))
                : null;
        };

        return suggestions ? (
            <div className="relative w-full" ref={dropdownRef}>
                <div className="p-16 bg-white-main rounded-[9px] absolute z-50 top-0 left-0 w-full">
                    {suggestions?.products[0]?.options && suggestions?.products[0]?.options.length > 0 && (
                        <div className="mb-32">
                            <p className="text-green-100 text-sm-title leading-[14px] mb-[18px]">{t('SUGGESTED_SEARCH')}</p>
                            {renderOptions(SUGGEST_TYPES.products, suggestions?.products[0].options)}
                        </div>
                    )}
                    {suggestions?.users[0]?.options && suggestions?.users[0]?.options.length > 0 && (
                        <div className="mb-32">
                            <p className="text-green-100 text-sm-title leading-[14px] mb-[18px]">{t('SUGGESTED_USERS')}</p>
                            {renderOptions(SUGGEST_TYPES.users, suggestions?.users[0].options)}
                        </div>
                    )}
                    {suggestions?.categories[0]?.options && suggestions?.categories[0]?.options.length > 0 && (
                        <div className="mb-32">
                            <p className="text-green-100 text-sm-title leading-[14px] mb-[18px]">{t('SUGGESTED_CATEGORIES')}</p>
                            {renderOptions(SUGGEST_TYPES.categories, suggestions?.categories[0].options)}
                        </div>
                    )}
                </div>
            </div>
        ) : null;
    }, [suggestions, t, memoizedHandleSelectSearch]);

    const memoizeCountrySelect = useMemo(() => {
        return (
            <div className="mr-[20px]">
                <CountriesSelect
                    name={location.name}
                    placeholder={t(location.placeholder)}
                    onChange={debouncedHandleFiltersChange}
                    value={getDropdownValue()}
                    serchPlaceholder={t('SEARCH_CITIES_PLACEHOLDER')}
                    className="bg-white-main border-b-0 px-8 h-[60px] rounded-[9px]"
                    wrapperClassName="pb-[0px]"
                    listWrapperClassName="min-w-[345px]"
                    isMulti
                />
            </div>
        );
    }, [filters?.cityIDFilter, t, debouncedHandleFiltersChange]);

    useEffect(() => {
        memoizedHandleSearch();
    }, [filters?.productFilter, memoizedHandleSearch]);

    useEffect(() => {
        document.addEventListener('mousedown', handleCloseDropdown);

        return () => {
            document.removeEventListener('mousedown', handleCloseDropdown);
        };
    }, []);

    return (
        <div className="bg-white-main">
            <div className="bg-search-bg pt-[62px] px-16 md:px-[0px]">
                <p className="text-center text-xl-title font-light leading-[40px]">{t('TITLE')}</p>
                <form noValidate onSubmit={handleRedirectToSearch}>
                    <div className="m-[auto] w-full md:w-[570px] pt-[20px]">
                        <Input
                            name={search.name}
                            className={cn('border-b-0 text-sm-title-light h-[60px] rounded-[9px] mb-[0px]', suggestions ? 'border border-green-100' : '')}
                            icon="search"
                            onChange={(e: React.FormEvent<HTMLInputElement>) => handleFiltersChange(search.name, e.currentTarget.value)}
                            iconClassName="text-grey-500"
                            autoFocus
                            withDropdown
                            customDropdown={customDropdown}
                            autoComplete="off"
                            value={filters?.productFilter || ''}
                        />
                        <div className="grid grid-cols-[50%_50%] md:grid-cols-[35%_65%] items-end">
                            {memoizeCountrySelect}
                            <div>
                                <Button type="submit" className="w-full h-[60px]">
                                    {t('SEARCH')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
                <HomeCategories lang={lang} />
            </div>
        </div>
    );
};

export default HomeHeader;
